


import card1 from '../../assets/images/cardimage/1.jpg'
import card2 from '../../assets/images/cardimage/2.jpg'
import card3 from '../../assets/images/cardimage/3.jpg'
import card4 from '../../assets/images/cardimage/4.jpg'
import card5 from '../../assets/images/cardimage/5.jpg'
import card6 from '../../assets/images/cardimage/6.jpg'
import card7 from '../../assets/images/cardimage/7.jpg'
import card8 from '../../assets/images/cardimage/8.jpg'
import card9 from '../../assets/images/cardimage/9.jpg'
import card10 from '../../assets/images/cardimage/10.jpg'
import cardJ from '../../assets/images/cardimage/11.jpg'
import cardQ from '../../assets/images/cardimage/12.jpg'
import cardK from '../../assets/images/cardimage/13.jpg'
import heart from '../../assets/images/cardimage/heartcard.png'
import shade from '../../assets/images/cardimage/shadecard.png'
import diamond from '../../assets/images/cardimage/diamondcard.png'
import check from '../../assets/images/check.png'
import club from '../../assets/images/cardimage/clubcard.png'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { casino, home } from '../../routesconfig/constant'
import { BackButton } from '../common/backbutton'
import LockIcon from '@mui/icons-material/Lock';
import { Cassinoifram } from './gameIframe/casinoifram'
import { teenpattilive } from '../../service/model'
import SubmitCasinoBet, { FetchUserDetails, GetcasinoBetList, GetcasinoData, GetcasinoResult } from '../../api/cassino/cassinogame'
import PlacebetCasino from '../common/placebetcasino'
import { BetSectionDialog, ConfirmBetDialog } from '../common/dialog'
import moment from 'moment-timezone'
import FlipCountdown from '../common/flipcountdown'
import { setSnackbaralert, setUserDetail } from '../../store/feature/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client';
import { formatDate } from "../../service/utilities/dateFormater";
import CompletedBetList from './utilities/casinoTable'
import { checkProfitLoss } from './utilities/checkCasinoprofitLoss'
import { pollingValue } from '../../service/utilities/poolingutils'

const DragonTiger = () => {
  const dispatch = useDispatch();
  const welcomeRef = useRef(null);
  const scrollToContent = () => {
    if (welcomeRef.current) {
      welcomeRef.current.scrollIntoView({
        behavior: "smooth",
        block: 'start',
        inline: 'nearest'

      });
    }
  }
  const navigate = useNavigate()



  const [placebet, setPlacebet] = useState(false)
  const [stakeamount, setStakeAmount] = useState("")
  const [betdata, setbetdata] = useState([]);
  const [confirmbet, setBetConform] = useState(false);
  const [dragontigerdata, setDragontigerdata] = useState([])
  const [dragonTigerResult, setDragontigerResult] = useState([])

  const SOCKET_SERVER_URL = "https://automaticapiserver.bbet247.net";
  const PASS_YOUR_EVENT_ID = "dt20"; // Replace with your actual event ID



  useEffect(() => {
    if(!pollingValue){
    
  
    
        const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
        socket.on("connect", () => {
       
          socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
        });
    
        socket.on("updatedData", (data) => {
          // Do work with your data
     
          setDragontigerdata(data.data);

          setDragontigerResult(data.data.result)

          
        });
    
         // Error handling
      socket.on("connect_error", (err) => {
        console.error("Connection error:", err);
      });
    
      socket.on("error", (err) => {
        console.error("Socket error:", err);
      });
    

    
    
        // Cleanup function to disconnect the socket when the component unmounts
        return () => {
          socket.disconnect();
        };
      }
    {
        const interval = setInterval(casinoRefetch, 1000);
        return () => clearInterval(interval);
    }
    
      }, []);



  const betprofit = stakeamount * (betdata?.rate - 1);

  const betConform = () => {
    setBetConform(true);
  };

  const handleClose = () => {
    setBetConform(false);
    setPlacebet(false)
  };
  const handelPlacebet = (value) => {
    scrollToContent()
    setPlacebet(true)
    setbetdata(value)
  }

  const handlingbet = (value) => {
    setStakeAmount(value);
  };
  const getISTDate = () => {
    return moment().tz('Asia/Kolkata').format('DD-MM-YYYY');
  };

  const getISTTime = () => {
    return moment().tz('Asia/Kolkata').format('HH:mm:ss');
  };

  const handleClick = () => {
    navigate(casino)
  }



  const { response: casinoResestList, refetch: casinoRefetchList } = GetcasinoBetList("dt20");
  const betlist = casinoResestList?.data || []
 

  const completedBetList = casinoResestList?.data
    ?.filter((res) => res.completed)
    ?.map((res) => ({
      ...res,
      createdDateTime: formatDate(res?.createdDateTime),
    }));

    const notCompletedBetList = casinoResestList?.data
    ?.filter((res) => !res.completed)
    ?.map((res) => ({
      ...res,
   
    }));


    
  const { response, loading, submit } = SubmitCasinoBet();

  const betSubmitConform = () => {

    const data = {
      gameType: gtype,
      roundId: betdata?.mid,
      rate: betdata?.rate,
      amount: stakeamount,
      selection: betdata?.nation,
    };
    if (data.amount >= 100 && data.amount <= 25000) {
      submit(data, (response) => {

        if (response?.metaData?.resultMessage === "Success") {
          betConform();
          handleClose()
          Refetchprofile()
          casinoRefetchList()
          setStakeAmount("")
          handleClose()
        }
        else(
          handleClose()
        )
      });
    }
    else{
      dispatch(
        setSnackbaralert({
          active: true,
          message: 'Your Amount Must be 100 to 25000',
          type: "error",
        })
      );
    }
  };




  useEffect(() => {
    // const intervalId = setInterval(casinoRefetch, 1000);
    const interval2Id = setInterval(() => { }, 3000);
    // const intervalprofile = setInterval(Refetchprofile, 5000);

    return () => clearInterval(interval2Id);
  }, [])

  const { response: responseprofile, refetch: Refetchprofile } = FetchUserDetails();
  const userdetails = responseprofile?.data;

  dispatch(setUserDetail(userdetails));

  // const { response: dragontigerresult, refetch: casinoResultrefetch } = GetcasinoResult("dt20");

  // const dragonTigerResult = dragontigerresult?.data || []
  const { response: dt20data, refetch: casinoRefetch } = GetcasinoData("dt20");

  useEffect(() => {
    if (dt20data) { 
      setDragontigerResult(dt20data?.data?.result);
    }
  }, [dt20data]);

const dragonTdata =  dt20data?.data
const dragontigerdatadt20 = pollingValue ? dragonTdata : dragontigerdata
  const t1 = dragontigerdatadt20?.data?.t1
  const timmer = t1 && t1[0]?.autotime
  const gtype = t1 && t1[0]?.gtype
  const roundid = t1 && t1[0]?.mid
  const tableroundId = roundid && roundid.toString().split('.')[1] || "0"

  // useEffect(() => {
  //   if (timmer === "20" || "15") {
  //     console.log(timmer)
  //     Refetchprofile()
  //      casinoRefetchList();
  //     // casinoResultrefetch()
  //   }
  // }, [timmer])


  useEffect(() => {
    console.log(timmer)
    const handleTimmer = () => {
      if (timmer === "5") {
  
        Refetchprofile();
      } else if (timmer === "15") {
        console.log("Timmer is 20");
        casinoRefetchList();
      }
    };
  
    handleTimmer();
  }, [timmer]);

   const [C1, setC1] = useState(null);
   const [C2, setC2] = useState(null);
  // const C1 = teenpattilive.find((card) => t1 && card.value === t1[0].c1)?.card;
  // const C2 = teenpattilive.find((card) => t1 && card.value === t1[0].c2)?.card;
  
const C1Value = t1 && t1[0].c1
const C2Value = t1 && t1[0].c2

useEffect(() => {
  updateCardValue();
}, [t1]);

const updateCardValue = () => {
  if (t1) {
  
    const delay = (C1Value.length > 1 || C2Value.length > 1) ? 3000 : 0;

    setTimeout(() => {
      const matchingCard1 = teenpattilive.find((card) => card.value === C1Value)?.card;
      const matchingCard2 = teenpattilive.find((card) => card.value === C2Value)?.card;

      if (matchingCard1) setC1(matchingCard1);
      if (matchingCard2) setC2(matchingCard2);
    }, delay); // Apply the delay conditionally based on length of C1Value or C2Value
  }
}
   

  

  // const C1 = teenpattilive.find((card) => t1 && card.value === t1[0].c1)?.card;
  // const C2 = teenpattilive.find((card) => t1 && card.value === t1[0].c2)?.card;



  const datat2 = dragontigerdatadt20?.data?.t2;


  let t2Dragon, t2Tiger, t2Tie, t2Pair, t2DEven, t2DOdd, t2DRed, t2DBlack, t2DCard1, t2DCard2, t2DCard3, t2DCard4, t2DCard5, t2DCard6, t2DCard7, t2DCard8, t2DCard9, t2DCard10, t2DCardJ, t2DCardQ, t2DCardK,
    t2TEven, t2TOdd, t2TRed, t2TBlack, t2TCard1, t2TCard2, t2TCard3, t2TCard4, t2TCard5, t2TCard6, t2TCard7, t2TCard8, t2TCard9, t2TCard10, t2TCardJ, t2TCardQ, t2TCardK
    ;

  useEffect(() => {
    if (t2DRed?.gstatus === "0") {
      handleClose()
    }
  }, [dragontigerdatadt20])

  const betReset = () => {
    setStakeAmount("")
  }


  if (Array.isArray(datat2)) {
    // Assign values inside the block without redeclaring
    t2Dragon = datat2[0];
    t2Tiger = datat2[1];
    t2Tie = datat2[2];
    t2Pair = datat2[3];
    t2DEven = datat2[4];
    t2DOdd = datat2[5];
    t2DRed = datat2[6];
    t2DBlack = datat2[7];
    t2DCard1 = datat2[8];
    t2DCard2 = datat2[9];
    t2DCard3 = datat2[10];
    t2DCard4 = datat2[11];
    t2DCard5 = datat2[12];
    t2DCard6 = datat2[13];
    t2DCard7 = datat2[14];
    t2DCard8 = datat2[15];
    t2DCard9 = datat2[16];
    t2DCard10 = datat2[17];
    t2DCardJ = datat2[18];
    t2DCardQ = datat2[19];
    t2DCardK = datat2[20];
    t2TEven = datat2[21];
    t2TOdd = datat2[22];
    t2TRed = datat2[23];
    t2TBlack = datat2[24];
    t2TCard1 = datat2[25];
    t2TCard2 = datat2[26];
    t2TCard3 = datat2[27];
    t2TCard4 = datat2[28];
    t2TCard5 = datat2[29];
    t2TCard6 = datat2[30];
    t2TCard7 = datat2[31];
    t2TCard8 = datat2[32];
    t2TCard9 = datat2[33];
    t2TCard10 = datat2[34];
    t2TCardJ = datat2[35];
    t2TCardQ = datat2[36];
    t2TCardK = datat2[37];
  }




  const DragonTigerCardList = [
    { t2DCard1 }, { t2DCard2 }, { t2Tie }, { t2Pair }, { t2DCard3 }, { t2DCard4 }, { t2DCard5 }, { t2DCard6 }, { t2DCard7 }, { t2DCard8 }, { t2DCard9 }, { t2DCard10 }, { t2DCardJ }, { t2DCardQ }, { t2DCardK },
    { t2TCard1 }, { t2TCard2 }, { t2TCard3 }, { t2TCard4 }, { t2TCard5 }, { t2TCard6 }, { t2TCard7 }, { t2TCard8 }, { t2TCard9 }, { t2TCard10 }, { t2TCardJ }, { t2TCardQ }, { t2TCardK },
  ]

  const cardsimgdragon = [
    { name: card1, value: 'Card 1', data: t2DCard1 || "" },
    { name: card2, value: 'Card 2', data: t2DCard2 || "" },
    { name: card3, value: 'Card 3', data: t2DCard3 || "" },
    { name: card4, value: 'Card 4', data: t2DCard4 || "" },
    { name: card5, value: 'Card 5', data: t2DCard5 || "" },
    { name: card6, value: 'Card 6', data: t2DCard6 || "" },
    { name: card7, value: 'Card 7', data: t2DCard7 || "" },
    { name: card8, value: 'Card 8', data: t2DCard8 || "" },
    { name: card9, value: 'Card 9', data: t2DCard9 || "" },
    { name: card10, value: 'Card 10', data: t2DCard10 || "" },
    { name: cardJ, value: 'Card J', data: t2DCardJ || "" },
    { name: cardQ, value: 'Card Q', data: t2DCardQ || "" },
    { name: cardK, value: 'Card K', data: t2DCardK || "" }
  ]


  const cardsimgtiger = [
    { name: card1, value: 'Card 1', data: t2TCard1 || "" },
    { name: card2, value: 'Card 2', data: t2TCard2 || "" },
    { name: card3, value: 'Card 3', data: t2TCard3 || "" },
    { name: card4, value: 'Card 4', data: t2TCard4 || "" },
    { name: card5, value: 'Card 5', data: t2TCard5 || "" },
    { name: card6, value: 'Card 6', data: t2TCard6 || "" },
    { name: card7, value: 'Card 7', data: t2TCard7 || "" },
    { name: card8, value: 'Card 8', data: t2TCard8 || "" },
    { name: card9, value: 'Card 9', data: t2TCard9 || "" },
    { name: card10, value: 'Card 10', data: t2TCard10 || "" },
    { name: cardJ, value: 'Card J', data: t2TCardJ || "" },
    { name: cardQ, value: 'Card Q', data: t2TCardQ || "" },
    { name: cardK, value: 'Card K', data: t2TCardK || "" }
  ];


  // Click handler to find and log all related objects
  const handleCardClick = (cardValue) => {
    const matchedCards = DragonTigerCardList.filter(cardObj => {
      const cardKey = Object.keys(cardObj)[0]; // Get the key (e.g., t2Card1)
      return cardObj[cardKey].nation === cardValue;
    });

    if (matchedCards.length > 0) {

    } else {

    }
  };

  const columns = [
    { id: "selection", label: "Bet" },
    { id: "rate", label: "Rate" },
    { id: "amount", label: "Amount" },
    { id: "result", label: "Result" },
    { id: "createdDateTime", label: "Date & Time" },
    { id: "profitAndLoss", label: "P/L" },
  ];
  
  
  const columnsopenbet = [
    { id: "selection", label: "Match Bet" },
    { id: "rate", label: "Odds" },
    { id: "amount", label: "Stake" },
  
  ];


  const userDetails = useSelector(state => state.auth.userdetail);
  

  return <>

    {
      t2DRed?.gstatus !== "0" &&

      <BetSectionDialog
      onClose={handleClose}
        open={placebet}
        content={
          <PlacebetCasino
          loading ={loading}
            placebet={placebet}
            setPlacebet={setPlacebet}
            setStake={setStakeAmount}
            stake={stakeamount}
            odds={betdata && betdata.rate}
            betnation={betdata && betdata.nation}
            betprofit={betprofit}
            handlingbet={handlingbet}
            betReset={betReset}
            onClose={handleClose}
            betSubmitConform={betSubmitConform}
          />
        }
      />
    }

    <div style={{ margin: '10px 0px' }}>
      <BackButton onClick={handleClick} />
    </div>

    <div className='dragon-tiger'>
      <div className='row'>
        <div className='col-md-8'>
          <div className="luckyseven">
            <div className="teenpatti-header">

              <div className='cmc'>
                <span style={{ fontWeight: '500', marginRight: '10px', fontSize: "14px" }}>
                  Dragon Tiger
                </span>
                <span style={{ fontSize: "14px" }}>
                  Round ID: {tableroundId || 0} <br />
                </span>
              </div>
              <div className='cmc' style={{ fontSize: '14px' }}>
                <span style={{ color: '' }} > Date: {getISTDate()}</span>
                <span style={{ color: '' }} > Time: {getISTTime()}</span>

              </div>

            </div>
            <div className="live_stream">
              <div className='main-websocket'>
                <Cassinoifram id={3035} />
              </div>
              <div className='overflow-card-section'>
                <div className='stream-card-section'>
                  <div className='cmd p-2'>
                    <img className='card-image' src={C1} />
                    <img className='card-image' src={C2} />
                  </div>
                </div>
              </div>
              <div className="overflow-casino-timer">
                <FlipCountdown endAt={timmer} />
              </div>

            </div>

            <div className="mt-2 row select_bet_box m-0">

              <div className=" col-md-3 ">
                <div onClick={() => { t2Dragon?.gstatus !== "0" && handelPlacebet(t2Dragon) }} className="tc d-grid">
                  <span>{t2Dragon?.rate || "0"}</span>
                  <div className='bet-button-overlay'>
                    <button className="bet-button">Dragon</button>
                    {t2Dragon?.gstatus === "0" && <OverflowContant />}
                  </div>
                  <span className='min-max'> min:{t2Dragon?.min} || max:{t2Dragon?.max}</span>
                </div>
              </div>
              <div className=" col-md-3 ">
                <div onClick={() => { t2Tie?.gstatus !== "0" && handelPlacebet(t2Tie) }} className="tc d-grid">
                  <span>{t2Tie?.rate || "0"}</span>
                  <div className='bet-button-overlay'>
                    <button className="bet-button">Tie</button>
                    {t2Tie?.gstatus === "0" && <OverflowContant />}
                  </div>
                  <span className='min-max'> min:{t2Tie?.min} || max:{t2Tie?.max}</span>
                </div>
              </div>
              <div className=" col-md-3 ">
                <div onClick={() => { t2Pair?.gstatus !== "0" && handelPlacebet(t2Pair) }} className="tc d-grid">
                  <span>{t2Pair?.rate || "0"}</span>
                  <div className='bet-button-overlay'>
                    <button className="bet-button">PAIR</button>
                    {t2Pair?.gstatus === "0" && <OverflowContant />}
                  </div>
                  <span className='min-max'> min:{t2Pair?.min} || max:{t2Pair?.max}</span>
                </div>
              </div>
              <div className=" col-md-3">
                <div onClick={() => { t2Tiger?.gstatus !== "0" && handelPlacebet(t2Tiger) }} className="tc d-grid">
                  <span>{t2Tiger?.rate || "0"}</span>
                  <div className='bet-button-overlay'>
                    <button className='bet-button'>Tiger</button>
                    {t2Tiger?.gstatus === "0" && <OverflowContant />}
                  </div>
                  <span className='min-max'> min:{t2Tiger?.min} || max:{t2Tiger?.max}</span>
                </div>
              </div>

            </div>
            
            <div style={{ margin: 0 }} className="row cmc mt-2">

              <div className="col-md-5  select_bet_box">
                <h5>Dragon</h5>
                <div className='cmc'>
                  <div onClick={() => { t2DEven?.gstatus !== "0" && handelPlacebet(t2DEven) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2DEven?.rate || "0"}</span>

                    <button className="bet_box_even">EVEN</button>
                    {t2DEven?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                  <div onClick={() => { t2DOdd?.gstatus !== "0" && handelPlacebet(t2DOdd) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2DOdd?.rate || "0"}</span>

                    <button className="bet_box_even">ODD</button>
                    {t2DOdd?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                  <div onClick={() => { t2DRed?.gstatus !== "0" && handelPlacebet(t2DRed) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2DRed?.rate || "0"}</span>
                    <button className="bet_box_even">
                      <div className='luckeybet-sign-button'>
                        <img src={heart} />
                        <img src={diamond} />
                      </div>
                    </button>
                    {t2DRed?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                  <div onClick={() => { t2DBlack?.gstatus !== "0" && handelPlacebet(t2DBlack) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2DBlack?.rate || "0"}</span>
                    <button className="bet_box_even">
                      <div className='luckeybet-sign-button'>
                        <img src={shade} />
                        <img src={club} />
                      </div>
                    </button>
                    {t2DBlack?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                </div>
                <span className='min-max'> min:{t2DRed?.min} || max:{t2DRed?.max}</span>
              </div>

              <div className="col-md-5 mt-2 select_bet_box">
                <h5>Tiger</h5>
                <div className='cmc'>
                  <div onClick={() => { t2TEven?.gstatus !== "0" && handelPlacebet(t2TEven) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2TEven?.rate || "0"}</span>

                    <button className="bet_box_even">EVEN</button>
                    {t2TEven?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                  <div onClick={() => { t2TOdd?.gstatus !== "0" && handelPlacebet(t2TOdd) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2TOdd?.rate || "0"}</span>

                    <button className="bet_box_even">ODD</button>
                    {t2TOdd?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                  <div onClick={() => { t2TRed?.gstatus !== "0" && handelPlacebet(t2TRed) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2TRed?.rate || "0"}</span>
                    <button className="bet_box_even">
                      <div className='luckeybet-sign-button'>
                        <img src={heart} />
                        <img src={diamond} />
                      </div>
                    </button>
                    {t2TRed?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                  <div onClick={() => { t2TBlack?.gstatus !== "0" && handelPlacebet(t2TBlack) }} className="tc d-grid" style={{ width: '45%', position: 'relative' }}>
                    <span>{t2TBlack?.rate || "0"}</span>
                    <button className="bet_box_even">
                      <div className='luckeybet-sign-button'>
                        <img src={shade} />
                        <img src={club} />
                      </div>
                    </button>
                    {t2TBlack?.gstatus === "0" && <OverflowContant />}
                    <span>0</span>
                  </div>
                </div>
                <span className='min-max'> min:{t2TEven?.min} || max:{t2TEven?.max}</span>
              </div>
            </div>
            <div style={{ margin: 0 }} className='row m-0'>
              <div className='select_bet_box mt-2'>
                <h5 className='tc'>Dragon {t2TCard1?.rate}</h5>
                <div className="mt-2 col-12  d-flex justify-content-center cardssection">
                  <div className="card-grid">
                    {
                      cardsimgdragon?.map((card, index) => (
                        <div onClick={() => { card.data?.gstatus !== "0" && handelPlacebet(card.data) }} key={index} className="card-container">
                          <div style={{ backgroundColor: 'red', position: 'relative' }} className=''>
                            <img className="card-image" src={card.name} alt={`Card ${index}`}

                            />
                            {card.data?.gstatus === "0" && <OverflowCard />}
                          </div>
                          <div className="card-text">{card.data?.rate}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <span className='min-max'> min:{t2DCard1?.min} || max:{t2DCard1?.max}</span>
              </div>
              <div className='select_bet_box mt-2'>
                <h5 className='tc'>Tiger {t2TCard1?.rate}</h5>
                <div className="mt-2 col-12  d-flex justify-content-center cardssection">
                  <div className="card-grid">
                    {
                      cardsimgtiger.map((card, index) => (
                        <div onClick={() => { card.data?.gstatus !== "0" && handelPlacebet(card.data) }} key={index} className="card-container">
                          <div style={{ backgroundColor: 'red', position: 'relative' }} className=''>
                            <img className="card-image" src={card.name} alt={`Card ${index}`}

                            />

                            {card.data?.gstatus === "0" && <OverflowCard />}
                          </div>
                          <div className="card-text">{card.data?.rate}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              <div className='w-100'>  <span className='min-max'> min:{t2TCard1?.min} || max:{t2TCard1?.max}</span> </div>

              </div>

            </div>

            <div className="last_result  cmc">
              <span>Last Result</span>
              <span>View Result</span>
            </div>
            <div className="bet-result " style={{ padding: "10px 10px" }}>
              {dragonTigerResult?.map((item, index) => (
                <span
                  key={index}
                  style={{
                    color: item.result === "1" ? "red" : item.result === "2" ? "yellow" : "black"
                  }}
                >
                  {item.result === "1" ? "D" : item.result === "2" ? "T" : item.result || "0"}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="col-md-4">
        {notCompletedBetList && notCompletedBetList.length != 0 && 

<div>

<div className="place-bet-h">Open Bet List</div>
              <CompletedBetList columns={columnsopenbet} listData={notCompletedBetList} />
            </div>

}
 {checkProfitLoss(betlist)?.totalAmount !== 0 &&
            <div>
              <div className="place-bet-h cmc">
                <div>Completed Bet List</div>
                <div
                  style={{
                    color: checkProfitLoss(betlist)?.isProfit
                      ? "yellow"
                      : "red",
                  }}
                >
                  P/L: {checkProfitLoss(betlist).profitLossAmount}
                </div>
              </div>
              <CompletedBetList columns={columns} listData={completedBetList} />
            </div>
}


</div>
      </div>


    </div>
    <div style={{ marginTop: '10px' }}>
      <BackButton onClick={handleClick} />
    </div>

  </>

}
export default DragonTiger


const OverflowContant = () => {
  return (
    <>
      <div className='overflow-dragon-tiger-btn1'>
        <LockIcon sx={{ color: 'white' }} />
      </div>
    </>
  )
}

const OverflowCard = () => {
  return (
    <>
      <div className='overflow-dragon-tiger-card'>
        <LockIcon sx={{ color: 'white' }} />
      </div>
    </>
  )
}