import { useDispatch } from "react-redux";
import { useGetAPI, usePostAPI } from "../../service/apiServices";
import { CasinoPlacebet, GetCasinobetList, GetCasinobetListbydate, GetCasinodata, GetCasinogameResult, GetCasinogameResultList, Userdetails } from "../../service/apiconst";


export const GetcasinoData = (item) => {
    debugger
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinodata(item),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },
    });
}



export const FetchUserDetails = () => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: Userdetails(),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },

    });
}


export const GetcasinoResult = (item) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinogameResult(item),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },
       
    });
}

export const GetcasinoBetList = (item) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinobetList(item),
        autoRunStop: false,
        dispatch: (e) => {
            // dispatch(e);
        },

    });
}
export const CasinobetListbydate = (params) => {
    const dispatch = useDispatch();
    return useGetAPI({
        contenttype: false,
        url: GetCasinobetListbydate(),
        autoRunStop: false,
        params,
        dispatch: (e) => {
            // dispatch(e);
        },

    });
}

function SubmitCasinoBet() {
    const dispatch = useDispatch();

    return usePostAPI({
        contenttype: false,
        url: CasinoPlacebet(),
        dispatch: (e) => {
            dispatch({ type: e.type, payload: e.payload });

         
            // if (e.payload.type !== "success") {
            // }
        },
      
    });
}

export default SubmitCasinoBet;