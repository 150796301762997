import React from "react";
import CustomCard from "../common/customcard";
import { Link, useNavigate } from "react-router-dom";
import { home } from "../../routesconfig/constant";
import { BackButton } from "../common/backbutton";
import nodata from "../../assets/images/nodata.jpg"

import { GetMatchsList } from "../../api/sassinmatch/sassionbet";
import { useSelector } from "react-redux";
import blockimg from "../../assets/images/block.png"

const Inplay = () => {

  const userDetails = useSelector(state => state.auth.userdetail);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(home);
  };

  const { response } = GetMatchsList();


  return (
    <>
      <div style={{ marginTop: '10px' }} className="inplay-container">
        <BackButton onClick={handleClick} />

        {userDetails?.blockBetting === true ?
          <BlockContainer /> :
          <div>

            {!response?.data ?
              <div className="nodata-img mt-2">
                <img src={nodata} />
              </div>
              :
              <div className="row">
                {response?.data?.map((card, index) => {

                  const newdate = card.startDateTime;


                  // Create a new Date object
                  const date = new Date(newdate);

                  // Options for formatting
                  const options = {
                    day: 'numeric',
                    month: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                  };


                  const formattedDate = date.toLocaleString('en-US', options);

                  return (

                    <div className="col-md-6" key={index}>
                      <Link to={`/inplaysession/${card.marketId}`} >
                        <CustomCard
                          heading={card.competitionName || 'International'}
                          title={card.name}
                          datetime={formattedDate}
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            }
          </div>
        }
        {response?.data &&
          <BackButton onClick={handleClick} />
        }
      </div>
    </>
  );
};

export default Inplay;




const BlockContainer = () => {

  return <>
    <div className='tc'>
      <img style={{ width: '200px' }} src={blockimg} />
      <h5>Block Cricket</h5>
    </div>
  </>
}