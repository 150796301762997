import React, { useEffect, useState } from 'react';

export const Cassinoifram = ({id}) => {

  return (
   <>
   <div  className='casinoifram'>
   <iframe
        src={`https://live.oldd247.com/?id=${id}`}
        width="100%"
    >
    </iframe>

   </div>
   </>
  );
};




// teen20 = 3030
// lucky7 = 3058
// ab20 =3053
// aaa= 3056
// dt20=3035


