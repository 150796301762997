import { useEffect, useState } from "react";
import casinotable from "../../assets/images/casino-girl3.jpg";
import { BackButton } from "../common/backbutton";
import { casino, home } from "../../routesconfig/constant";
import { useNavigate } from "react-router-dom";
import {
  BetSectionDialog,
  ConfirmBetDialog,
  TeenpattiRulesDialog,
} from "../common/dialog";
import check from "../../assets/images/check.png";
import LockIcon from "@mui/icons-material/Lock";
import { Cassinoifram } from "./gameIframe/casinoifram";
import SubmitCasinoBet, {
  FetchUserDetails,
  GetcasinoBetList,
  GetcasinoData,
  GetcasinoResult,
  GetcasinoResultList,
} from "../../api/cassino/cassinogame";
import { teenpattilive } from "../../service/model";
import PlacebetCasino from "../common/placebetcasino";

import moment from "moment-timezone";
import FlipCountdown from "../common/flipcountdown";
import { setSnackbaralert, setUserDetail } from "../../store/feature/authSlice";
import { useDispatch } from "react-redux";
import io from "socket.io-client";
import { formatDate } from "../../service/utilities/dateFormater";
import { checkProfitLoss } from "./utilities/checkCasinoprofitLoss";
import CompletedBetList from "./utilities/casinoTable";
import { pollingValue } from "../../service/utilities/poolingutils";

const TeenPatti = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [placebet, setPlacebet] = useState(false);
  const [stakeamount, setStakeAmount] = useState();
  const [confirmbet, setBetConform] = useState(false);
  const [betdata, setbetdata] = useState([]);
  const [teenrules, setTeenrules] = useState(false);
  const [teenData, setTeendata] = useState({});
  const [teenbetResult, setteenResult] = useState([]);

  

  const SOCKET_SERVER_URL = "https://socket.bbet247.net";
  const PASS_YOUR_EVENT_ID = "teen20"; // Replace with your actual event ID

  useEffect(() => {
if(!pollingValue){


    const socket = io(SOCKET_SERVER_URL, { transports: ["websocket"] });
    socket.on("connect", () => {

      socket.emit("addOnEVENT", PASS_YOUR_EVENT_ID);
    });

    socket.on("updatedData", (data) => {
      // Do work with your data
  
      setTeendata(data.data);
      setteenResult(data.data.result)
    });

     // Error handling
  socket.on("connect_error", (err) => {
    console.error("Connection error:", err);
  });

  
  socket.on("error", (err) => {
    console.error("Socket error:", err);
  });



    // Cleanup function to disconnect the socket when the component unmounts
    return () => {
      socket.disconnect();
    };
  }
{

    const interval = setInterval(casinoRefetch, 1000);
    return () => clearInterval(interval);
}

  }, []);



  useEffect(() => {
    
    // const interval = setInterval(casinoRefetch, 1000);
    // const interval2Id = setInterval(casinoResultrefetch, 3000);
    const intervalprofile = setInterval(Refetchprofile, 5000);
    return () => clearInterval( intervalprofile);
  }, []);

  const { response: responseprofile, refetch: Refetchprofile } =
    FetchUserDetails();
  const userdetails = responseprofile?.data;

  dispatch(setUserDetail(userdetails));

  const { response: casinoResestList, refetch: casinoRefetchList } =
    GetcasinoBetList("teen20");
  const betlist = casinoResestList?.data || [];


  

  const completedBetList = casinoResestList?.data
    ?.filter((res) => res.completed)
    ?.map((res) => ({
      ...res,
      createdDateTime: formatDate(res?.createdDateTime),
    }));

    const notCompletedBetList = casinoResestList?.data
    ?.filter((res) => !res.completed)
    ?.map((res) => ({
      ...res,
   
    }));


  const { response: teenpattidata, refetch: casinoRefetch } =
    GetcasinoData("teen20");



  // const { response: teenpattiresult, refetch: casinoResultrefetch } =
  //   GetcasinoResult("teen20");

  // const teenbetResult = teenpattiresult?.data || [];



  const teen20data =   pollingValue ? teenpattidata?.data?.data : teenData?.data;
  useEffect(() => {
    if (teenpattidata) { 
      setteenResult(teenpattidata?.data?.result);
    }
  }, [teenpattidata]);

  const t1 = teen20data?.t1;
  const timmer = t1 && t1[0]?.autotime;
  const gtype = t1 && t1[0]?.gtype;
  const roundid = t1 && t1[0]?.mid;
  const tableroundId = (roundid && roundid.toString().split(".")[1]) || "0";
  const t2A = teen20data?.t2[0];
  const t2B = teen20data?.t2[2];
  // const playerA = t2[0].nation
  // const playerB = t2[2].nation

  const Blockcard = teenpattilive.find((item) => item.value === "1")?.card;

  // const [C1, setC1] = useState(null);
  // const [C2, setC2] = useState(null);
  // const [C3, setC3] = useState(null);
  // const [C4, setC4] = useState(null);
  // const [C5, setC5] = useState(null);
  // const [C6, setC6] = useState(null);


//  const C1Value = t1 && t1[0].c1
// const C2Value = t1 && t1[0].c2
// const C3Value = t1 && t1[0].c3
// const C4Value = t1 && t1[0].c4
// const C5Value = t1 && t1[0].c5
// const C6Value = t1 && t1[0].c6

 const [cards, setCards] = useState({ C1: null, C2: null, C3: null, C4: null, C5: null, C6: null });

useEffect(() => {
  if (t1) {
    updateCardValues();
  }
}, [t1]);

const updateCardValues = () => {
  const cardKeys = ["c1", "c2", "c3", "c4", "c5", "c6"]; // Mapping keys from t1

  let hasDelay = cardKeys.some((key) => t1[0][key]?.length > 1); // Check if any card has a length > 1
  let delay = hasDelay ? 2000 : 0;

  setTimeout(() => {
    const updatedCards = {};

    cardKeys.forEach((key, index) => {
      const cardKey = `C${index + 1}`; // Corresponding state key (C1, C2, etc.)
      const cardValue = t1[0][key];

      if (cardValue?.length > 0) {
        updatedCards[cardKey] =
          teenpattilive.find((card) => card.value === cardValue)?.card || Blockcard;
      } else {
        updatedCards[cardKey] = Blockcard; // Set to Blockcard if no match or value is empty
      }
    });

    setCards((prev) => ({ ...prev, ...updatedCards }));
  }, delay);
};
 
  const endAt = timmer > 0 ? timmer : 0;
  const formattedEndAt = String(endAt).padStart(2, "0"); // Format to two digits
  const countdownDuration = 30;

  useEffect(() => {
    if (t2B?.gstatus === "0") {
      handleClose();
    }
  }, [teen20data]);


  

  // Debugging log
  useEffect(() => {
    if (timmer === "24" || "20") {
      Refetchprofile();
      casinoRefetchList();
    }
  }, [timmer]);

  const betprofit = stakeamount * (betdata?.rate - 1);

  const handlingbet = (value) => {
    setPlacebet(true);
    setStakeAmount(value);
  };

  const handleClick = () => {
    navigate(casino);
  };
  const betConform = () => {
    setBetConform(true);
  };

  const getISTDate = () => {
    return moment().tz("Asia/Kolkata").format("DD-MM-YYYY");
  };

  const getISTTime = () => {
    return moment().tz("Asia/Kolkata").format("HH:mm:ss");
  };

  const betReset = () => {
    setStakeAmount("");
  };

  const { response, loading, submit } = SubmitCasinoBet();
  const betSubmitConform = () => {
    const data = {
      gameType: gtype,
      roundId: betdata?.mid,
      rate: betdata?.rate,
      amount: stakeamount,
      selection: betdata?.nation,
    };

    if (data.amount >= 100 && data.amount <= 25000) {
      submit(data, (response) => {
        if (response?.metaData?.resultMessage === "Success") {
          casinoRefetchList();
          Refetchprofile()
          betConform();
          setStakeAmount("");
          handleClose()
        }
      });
    }
    else{
      dispatch(
        setSnackbaralert({
          active: true,
          message: 'Your Amount Must be 100 to 25000',
          type: "error",
        })
      );
    }
  };

const handelRuleClose =() =>{
  setTeenrules(false);
}

  const handleClose = () => {
    setStakeAmount("");
    setBetConform(false);
    setPlacebet(false);
 
  };

  const handelPlacebet = (value) => {
    setPlacebet(true);
    setbetdata(value);
  };
  const handelteenpattiRules = () => {
    setTeenrules(true);
  };

  const tablecolumn = [
    "S/N",
    "Team Name",
    "Match Date",
    "League",
    "Inplay",
    "More Info",
  ];

  const columns = [
    { id: "selection", label: "Bet" },
    { id: "rate", label: "Rate" },
    { id: "amount", label: "Amount" },
    { id: "result", label: "Result" },
    { id: "createdDateTime", label: "Date & Time" },
    { id: "profitAndLoss", label: "P/L" },
  ];


  const columnsopenbet = [
    { id: "selection", label: "Match Bet" },
    { id: "rate", label: "Odds" },
    { id: "amount", label: "Stake" },

  ];

  return (
    <>
      {t2B?.gstatus !== "0" && (
        <BetSectionDialog
          onClose={handleClose}
          open={placebet}
          content={
            <PlacebetCasino
            loading ={loading}
              placebet={placebet}
              setPlacebet={setPlacebet}
              setStake={setStakeAmount}
              stake={stakeamount}
              odds={betdata && betdata.rate}
              betnation={betdata && betdata.nation}
              betprofit={betprofit}
              handlingbet={handlingbet}
              betReset={betReset}
              onClose={handleClose}
              betSubmitConform={betSubmitConform}
            />
          }
        />
      )}

      <TeenpattiRulesDialog onClose={handelRuleClose} open={teenrules} />

      <div style={{ margin: "10px 0px" }}>
        <BackButton onClick={handleClick} />
      </div>
      <div className="casino_container">
        <div className="row">
          <div className="teenpatti container-fluid col-md-8">
            <div className="teenpatti-header  t-white">
              <div className="cmc">
                <span
                  style={{
                    fontWeight: "500",
                    marginRight: "10px",
                    fontSize: "",
                  }}
                >
                  20-20 TEENPATTI{" "}
                  <b
                    onClick={() => handelteenpattiRules()}
                    style={{ color: "#00faff", fontSize: "14px" }}
                  >
                    Rules{" "}
                  </b>
                </span>
                <span style={{ fontSize: "" }}>
                  Round ID: {tableroundId || 0} <br />
                </span>
              </div>
              <div className="cmc" style={{ fontSize: "" }}>
                <span style={{ color: "" }}> Date: {getISTDate()}</span>
                <span style={{ color: "" }}> Time: {getISTTime()}</span>
              </div>
            </div>
            <div className="live_stream">
              <div className="main-websocket">
                <Cassinoifram id={3030} />
              </div>

              <div className="overflow-card-section">
                <div className="stream-card-section">
                  <p>PLAYER A</p>

                  <div className="cmd">
                    <img className="card-image" src={cards.C1} />
                    <img className="card-image" src={cards.C2} />
                    <img className="card-image" src={cards.C3} />
                  </div>
                </div>
                <div>
                  <p>PLAYER B</p>
                  <div className="cmd">
                    <img className="card-image" src={cards.C4} />
                    <img className="card-image" src={cards.C5} />
                    <img className="card-image" src={cards.C6} />
                  </div>
                </div>
              </div>

              <div className="overflow-casino-timer">
                <FlipCountdown endAt={timmer} />
              </div>
            </div>
            <div className="mt-2">
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td className="box-6">Min {  t1 && t1[0]?.min} | Max  {t1 && t1[0]?.max}</td>
                    <td
                      onClick={() => {
                        t2A?.gstatus !== "0" && handelPlacebet(t2A);
                      }}
                      className="box-4 position-relative"
                    >
                      <span>
                        <b>Back</b>
                      </span>{" "}
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className="box-6">player A</td>
                    <td
                      onClick={() => {
                        t2A?.gstatus !== "0" && handelPlacebet(t2A);
                      }}
                      className="box-4 position-relative"
                    >
                      <span>
                        <b>{t2A?.rate}</b>
                      </span>{" "}
                      <br />
                      <span>0</span>
                      {t2A?.gstatus === "0" && (
                        <div className="overflow-teenpatti">
                          <LockIcon sx={{ color: "white" }} />{" "}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr className="mt-2">
                    <td className="box-6">player B</td>
                    <td
                      onClick={() => {
                        t2B?.gstatus !== "0" && handelPlacebet(t2B);
                      }}
                      className="box-4 position-relative"
                    >
                      <div>
                        <span>
                          <b>{t2B?.rate}</b>
                        </span>{" "}
                        <br />
                        <span>0</span>
                      </div>
                      {t2B?.gstatus === "0" && (
                        <div className="overflow-teenpatti">
                          <LockIcon sx={{ color: "white" }} />{" "}
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className=" mt-3">
              <div className=" cmc last_result">
                <span>Last Result</span>
                <span>View Result</span>
              </div>
              <div className="bet-result" style={{ padding: "10px 10px" }}>
                {teenbetResult?.map((item, index) => (
                  <span
                    key={index}
                    style={{
                      color: item.result === "1" ? "#ffee01" : "#ff1f1f",
                    }}
                  >
                    {item.result === "1"
                      ? "A"
                      : item.result === "3"
                      ? "B"
                      : item.result}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-4">
           
{notCompletedBetList && notCompletedBetList.length != 0 && 

<div>
<div className="place-bet-h">Open Bet List</div>
              <CompletedBetList columns={columnsopenbet} listData={notCompletedBetList} />
            </div>
}
 {checkProfitLoss(betlist)?.totalAmount !== 0 &&
            <div>
              <div className="place-bet-h cmc">
                <div>Completed Bet List</div>
                <div
                  style={{
                    color: checkProfitLoss(betlist)?.isProfit
                      ? "yellow"
                      : "red",
                  }}
                >
                  P/L: {checkProfitLoss(betlist).profitLossAmount}
                </div>
              </div>
              <CompletedBetList columns={columns} listData={completedBetList} betlist={checkProfitLoss(betlist)} />
            </div>
}

          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <BackButton onClick={handleClick} />
      </div>
    </>
  );
};
export default TeenPatti;
