import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TransactionLedgerType } from "../../service/model";
import CommanTable from "../common/commontable";
import { UseLedgerapi } from "../../api/login/useLogin";
import { formatDate } from "../../service/utilities/dateFormater";
import { roundToDecimals } from "../../service/utilities/roundDecimal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, IconButton } from "@mui/material";
import { completegame, home } from "../../routesconfig/constant";
import { BackButton } from "../common/backbutton";


const Myledger = () => {
  const navigate = useNavigate();
  // const accountid = JSON.parse(sessionStorage.getItem("account") || "{}");
  const userId = sessionStorage.getItem("userid");

  const { submit: getledgerData } = UseLedgerapi()

  const [dateObj, setDateObj] = useState({ startDate: null, endDate: null });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchKey, setSearchKey] = useState("");

  const [agenttabledata, setAgentTabledata] = useState();
  const [totalDataLength, setTotalDataLength] = useState(false);
  const [totaldatacount , setTabledataCount] = useState()
   const [credittotal, setCreditTotal] = useState(0);
  const [debittotal, setDebitTotal] = useState(0);



  const pagination = {

  }




  const fatchStatementData = (id) => {

    getledgerData(pagination,
      (response) => {
        const agenttabledata = response?.data?.records?.map(
          (res, index) => ({
            ...res,
            eventName: EditUsername(res),         
            createdDate: formatDate(res.createdDate),
            credit: (res.amount > 0 ? (roundToDecimals(res.amount)) : 0),
            debit: (res.amount < 0 ? (roundToDecimals(res.amount)) : 0),
            balance: (roundToDecimals(res.balance)),
            ledgerType: (TransactionLedgerType.find(item => item.value === res.ledgerType)).name


          })
        );

       
        setAgentTabledata(agenttabledata);
        setTabledataCount(agenttabledata.length);
        setTotalDataLength(response?.data?.totalRecords);
        setCreditTotal(Math.abs(response?.data?.credit))
        setDebitTotal(Math.abs(response?.data?.debit))
      },
       { currentRecordsCount: id  }
    )
  }
  useEffect(() => {
    fatchStatementData()
  }, [page, rowsPerPage, dateObj, searchKey]);

  const columns = [
    { id: "date", label: "Date", align: "center" },
    { id: "eventName", label: "Evenet Name",},
    { id: "winner", label: "Won By", align: "center" },
    { id: "debit", label: "Debit", align: "center" },
    { id: "credit", label: "Credit", align: "center" },
    { id: "balance", label: "Balance", align: "center" },
    { id: "ledgerType", label: "Type", align: "center" },
    { id: "createdDate", label: "Settlement Date", align: "center" },
  ];

  const EditUsername = (row) => {
    
    return <div style={{color:'#0a58ca'}} onClick={(event) => handleUserAction(row)}>
        <VisibilityIcon />
        <span> {row.eventName}</span>
    </div>
  }
 

  const  handleUserAction =(row) =>{
    sessionStorage.setItem("ledgerdata", JSON.stringify(row)); 
navigate(completegame)
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    debugger
    setRowsPerPage(parseInt((event?.target?.value || 10), 10));
    setPage(0); // Reset to first page whenever rows per page changes
  };

  const onDateChange = (dateObject) => {
    setDateObj(dateObject);
    handleRowsPerPageChange();
  };

const handletableSeeMore = () =>{
  fatchStatementData(totaldatacount)
}

const handleClick = () => {
  navigate(home);
};


  const tabletool = () => {

  }
  return (

    <>
    <div className="mt-2">
      <BackButton onClick={handleClick} />
      </div>
      <div className='head-title mt-2'>My Ledger</div>

      {/* <div className="lanedane-container">
        <div className="row">
          <div className="col-md-4">
            <div style={{ backgroundColor: "#dd2727bd" }} className="items">
              <span>Lena</span>
              <span>{roundToDecimals(credittotal)}</span>
            </div>


          </div>
          <div className="col-md-4 ">
            <div style={{ backgroundColor: "#3ba146" }} className="items">
              <span>Dena</span>
              <span>{roundToDecimals(debittotal)}</span>
            </div>

          </div>


        </div>
      </div> */}
      <div className='mt-3'>
        <CommanTable
          title={"My Ledger"}
          columns={columns}
          rows={agenttabledata}
          totalDataLength={totalDataLength}
          page={page}
          rowsPerPage={rowsPerPage}
          options={{
            hidePagination: true,
            // hidetablehead: true
          }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          tabletool={tabletool}
          searchCallBack={(e) => {
            setSearchKey(e);
          }}
        />
      </div>
      {totaldatacount === 50 &&
<div className="cmc">
  <div></div>
      <Button
                onClick={handletableSeeMore}
                sx={{ backgroundColor: "#5856D6", margin: "10px" }}
                variant="contained"
              >
                See More
              </Button>
              </div>
            
}
<div className="mt-2">
      <BackButton onClick={handleClick} />
      </div>
    </>
  );
}
export default Myledger;





