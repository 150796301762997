
import teenpatti from '../../assets/images/teenpatti2.jpg';
import andarbahar from '../../assets/images/andarbahar2.jpg';
import lucky_seven from '../../assets/images/lucky72.jpg'
import dragontig from '../../assets/images/dragontig2.0.jpg'
import amarakbarant from '../../assets/images/amarakbar2.png'
import akbarlotteryimg from '../../assets/images/akbarlottery2.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { akbarlottery, amarakbar, andarbahargame, avaitorgame, coinflip, dragontiger, home, katrinadepika, luckyseven, teenpattigame } from '../../routesconfig/constant';
import { BackButton } from '../common/backbutton';
import { useSelector } from 'react-redux';
import blockimg from "../../assets/images/block.png"
const Casino = () => {

  const userDetails = useSelector(state => state.auth.userdetail);



  const navigate = useNavigate()





  const casinodata = [
    {
      img: dragontig,
      name: "Dragon Tiger",
      link: dragontiger,
    },
    {
      img: teenpatti,
      name: "Tenn Patti",
      link: teenpattigame,
    },
    {
      img: lucky_seven,
      name: "Lucky 7",
      link: luckyseven,
    },
    // {
    //     img:andarbahar,
    //     name: "Aandar Bahar",
    //     link: andarbahargame,
    // },

    // {
    //     img:amarakbarant,
    //     name: "Amar Akbar Anthony",
    //     link:amarakbar,
    // },

    // {
    //     img:akbarlotteryimg,
    //     name: "Akbar Lottery",
    //     link:akbarlottery,
    // },

  ]

  const handleClick = () => {
    navigate(home);
  };



  return (
    <>
      <div className='main-casion-container'>
        <BackButton onClick={handleClick} />

        {
          userDetails?.blockCasino === true ?
          <BlockContainer />
          
            : 
            <div className="row">
            {casinodata.map((item, index) => (

              <div className=" col-sm-12 col-md-6 mt-2 casino_box"
                key={index}
              >

                <Link to={item?.link ? item?.link : '/'}>
                  <img src={item.img} />
                  {/* <h5>{item.name}</h5> */}
                </Link>
                <div className='casino-text'>{item.name}</div>
              </div>

            ))}
          </div>
        }
        <div className='mt-2'>
          <BackButton onClick={handleClick} />
        </div>
      </div>

    </>
  )
}
export default Casino


const BlockContainer = () => {

  return <>
    <div className='tc'>
      <img style={{ width: '200px' }} src={blockimg} />
      <h5>Block Casino</h5>
    </div>
  </>

}