import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import '../../assets/matchSummary.css';

export const MatchSummary = ({scorelink}) => {

   const scoreCardUrl = `${scorelink}`

 
   let height;

   if (scoreCardUrl.includes("7777")) {
       height = '107px';
   } else if (scoreCardUrl.includes("777")) {
       height = '190px';
   } else {
       height = 'auto';
   }

  return (
   <>
   <div style={{padding:'0px',
   }} className='scorecard'>
   <iframe
   style={{
    width:"100%",
    height: height
   }}
        src= {scoreCardUrl}   
    >
    </iframe>

   </div>
   </>
  );
};

